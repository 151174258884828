import Base from '@/libs/core/Base'

const fields = {
  entity_type: null,
  name: null,
  circle: 'suppliers',
  contact_person: {
    name: null,
    phone: null,
    password: null,
  },
  registrar: {
    agent_code: '',
    client_slug: '',
  }
}

export default class Auth extends Base {
  constructor () {
    super(fields);
  }

  register () {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.getFields()
        data.contact_person.phone = data.contact_person.phone.replaceAll(/[ +]/g, '')
        // data.registrar.client_slug = data.registrar.client_slug.replaceAll(/[ +]/g, '')
        let response = await this.form.submit('post', 'iam/register', data)
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}