<template>
  <base-dialog-form
    title="New Supplier"
    :dialog="dialog"
    :loading="loading"
    action-text="Save Supplier details"
    @close="$emit('close')"
    @submit="register()"
  >
    <template #content>
      <v-card-title>
        <div>
          <p class="body-1">
            Register a supplier account
          </p>
        </div>
      </v-card-title>
      <v-card-text>
        <v-select
          dense
          outlined
          persistent-hint
          class="rounded-lg"
          label="Entity type"
          :items="entityTypes"
          v-model="authObj.entity_type"
          :hint="errors.get('entity_type')"
          :error="errors.has('entity_type')"
          @input="errors.clear('entity_type')"
        ></v-select>

        <v-text-field
          v-if="authObj.entity_type == 'organization'"
          dense
          outlined
          persistent-hint
          class="rounded-lg"
          label="Business name"
          v-model="authObj.name"
          :hint="errors.get('name')"
          :error="errors.has('name')"
          @input="errors.clear('name')"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          persistent-hint
          class="rounded-lg"
          label="Contact name"
          v-model="authObj.contact_person.name"
          :hint="errors.get('contact_person.name')"
          :error="errors.has('contact_person.name')"
          @input="errors.clear('contact_person.name')"
        ></v-text-field>

        <div class="mb-7">
          <vue-tel-input 
            label="test"
            class="outlined rounded-lg"
            v-model="authObj.contact_person.phone"
          ></vue-tel-input>
          <p
            v-if="errors.has('contact_person.phone')"
            class="ma-0 px-3"
            style="color: #e74c3c; font-size: 12px"
          >
            {{ errors.get('contact_person.phone') }}
          </p>
        </div>
      </v-card-text>
    </template>
    </base-dialog-form>
  </template>
  
  <script>
import Auth from '@/libs/iam/RegisterSupplier';

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },

    supplier: {
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      authObj: new Auth(),
      entityTypes: [
        { text: 'Individual', value: 'individual' },
        { text: 'Organization', value: 'organization' },
      ],
    }
  },

  computed: {
    errors () {
      return this.authObj.form.errors
    },
  },
  

  methods: {
    register () {
      if (!this.loading) {
        this.loading = true
        this.authObj.register().then((response) => {
          this.$emit('close')
          flash(response)
        }).finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>